// =================================== //
//  Start Section Gap Styles//
// =================================== //
@use "./variables" as vars;
@use "./mixins" as m;
@use "./flexbox" as flex;

.section-gap {
  padding: 100px 0;
  @media (max-width: 991px) {
    padding: 80px 0;
  }
}

.section-gap-top {
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-top: 80px;
  }
}

// =================================== //
//  End Section Gap Styles//
// =================================== //
// =================================== //
//  End Buttons Styles//
// =================================== //

.gradient-bg {
  @include m.gradient(90deg, vars.$primary-color2, vars.$primary-color);
}

.gradient-bg-2 {
  @include m.gradient(90deg, vars.$primary-color3, vars.$primary-color4);
}

.primary-btn {
  line-height: 48px;
  padding: 0 45px;
  border-radius: 25px;
  color: vars.$black;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  z-index: 2;
  border: 1px solid transparent;
  cursor: pointer;
  background-size: 200% auto;
  background-origin: border-box;
  @include m.transition();
  background: #fff;
  -webkit-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #a8a8a8,
    1px 7px 7px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #a8a8a8,
    1px 7px 7px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #a8a8a8,
    1px 7px 7px 3px rgba(0, 0, 0, 0.3);
  &:focus {
    outline: none;
  }
  span {
    margin-left: 10px;
    font-weight: 500;
    left: 0;
    position: relative;
    @include m.transition();
  }
  &:hover {
    color: vars.$black;
    -webkit-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #fff,
      1px 7px 7px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #fff,
      1px 7px 7px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #fff,
      1px 7px 7px 3px rgba(0, 0, 0, 0.3);
    background-position: right center;
    span {
      left: 5px;
    }
  }
  &.gray {
    background: #f9f9f9;
    color: vars.$title-color;
    &:hover {
      color: vars.$white;
      @extend .gradient-bg;
    }
  }
}
.secondary-btn {
  line-height: 48px;
  padding: 0 45px;
  border-radius: 25px;
  color: vars.$white;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  z-index: 2;
  border: 1px solid transparent;
  background: #0297fe;

  cursor: pointer;
  background-size: 200% auto;
  background-origin: border-box;
  -webkit-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #007eb7,
    1px 7px 7px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #007eb7,
    1px 7px 7px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #007eb7,
    1px 7px 7px 3px rgba(0, 0, 0, 0.3);
  @include m.transition();
  &:focus {
    outline: none;
  }
  span {
    margin-left: 10px;
    font-weight: 700;
    left: 0;
    position: relative;
    @include m.transition();
  }
  &:hover {
    color: vars.$white;
    box-shadow: 0px 10px 30px 0 rgba(124, 33, 255, 0.3);
    background-position: right center;
    span {
      left: 5px;
    }
    // background: #0297fe;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#0095da),
      to(#0297fe)
    );
    background-image: -webkit-linear-gradient(bottom, #0095da, #0297fe);
    background-image: -moz-linear-gradient(bottom, #0095da, #0297fe);
    background-image: -o-linear-gradient(bottom, #0095da, #0297fe);
    background-image: linear-gradient(to top, #0095da, #0297fe);
    -webkit-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #0097d9,
      1px 7px 7px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #0097d9,
      1px 7px 7px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 1px 0px #fff, 0px 5px 0px #0097d9,
      1px 7px 7px 3px rgba(0, 0, 0, 0.3);
  }
  &.gray {
    background: #f9f9f9;
    color: vars.$title-color;
    &:hover {
      color: vars.$black;
      @extend .gradient-bg;
    }
  }
}
.desktop-buttons {
  @media (min-width: 768px) {
    display: flex !important;
  }
  @media (max-width: 767.98px) {
    display: none !important;
  }
}
.mobile-buttons {
  @media (min-width: 768px) {
    display: none !important;
  }
  @media (max-width: 767.98px) {
    display: flex !important;
    position: absolute;
    bottom: 25px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  @media (max-width: 575.98px) {
    display: flex !important;
    position: absolute;
    bottom: 25px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .banner-content {
    position: absolute;
    bottom: 0px;
  }
  button.secondary-btn {
    @media (max-width: 575.98px) {
      width: 150px !important;
      padding: 0 !important;
    }
  }
}
// =================================== //
// End Buttons Styles//
// =================================== //
// =================================== //
// Start Section Title and Content Styles//
// =================================== //
.section-title {
  margin-bottom: 50px;
  @media (max-width: 991px) {
    margin-bottom: 50px;
  }
  .line {
    height: 7px;
    width: 100px;
    background: #0297fe;
  }
  .lines {
    height: 2px;
    width: 50px;
    text-align: center;
    background: #8e42f7;
    display: inline-block;
    position: relative;
    top: -16px;
    &:before {
      position: absolute;
      left: -30px;
      top: 5px;
      height: 2px;
      width: 50px;
      background: #8e42f7;
      content: "";
    }
    &:after {
      position: absolute;
      right: -30px;
      top: 5px;
      height: 2px;
      width: 50px;
      background: #8e42f7;
      content: "";
    }
  }
  h2 {
    margin: 0px 0px 10px;
    @media (max-width: 767px) {
      font-size: 25px;
    }
  }
  p {
    margin: 0;
  }
}
// =================================== //
// End Section Title and Content Styles//
// =================================== //
// =================================== //
// Start Video Play Styles//
// =================================== //
.video-play-button {
  position: relative;
  box-sizing: content-box;
  display: inline-block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 20px 8px 18px 28px;
  border: 0px;
  cursor: pointer;
  background: transparent;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    @include m.transform(translate(-50%, -50%));
    display: block;
    width: 60px;
    height: 60px;
    @extend .gradient-bg-2;
    border-radius: 50%;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:before {
    z-index: 0;
    @include m.animation(pulse-border 2000ms ease-out infinite);
  }
  &:after {
    z-index: 1;
    @include m.transition(all 200ms ease);
    box-shadow: 0px 10px 30px rgba(39, 0, 110, 0.3);
  }
  span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 16px solid #ffffff;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  &:hover {
    &:after {
      background-color: darken(vars.$primary-color, 30%);
    }
  }
}
@include m.keyframes(pulse-border) {
  0% {
    @include m.transform(translate(-50%, -50%) translateZ(0) scale(1));
    opacity: 0.7;
  }
  100% {
    @include m.transform(translate(-50%, -50%) translateZ(0) scale(1.5));
    opacity: 0.1;
  }
}
// =================================== //
// End Video Play Styles//
// =================================== //
// =================================== //
// Start Owl Nav Styles//
// =================================== //
.owl-nav {
  @include flex.flexbox();
  @include flex.flex-direction(column);
  position: absolute;
  @include m.transform(translateY(-50%));
  @media (max-width: 1199px) {
    display: none !important;
  }
  .owl-prev,
  .owl-next {
    &:focus {
      box-shadow: none;
      outline: none;
    }
    img {
      @include m.filter(grayscale(100%));
      @include m.transition();
    }
    &:hover {
      img {
        @include m.filter(grayscale(0%));
      }
    }
  }
}
// =================================== //
// End Owl Nav Styles//
// =================================== //
// =================================== //
// Start Progress Bar Styles//
// =================================== //
progress[value] {
  position: fixed;
  top: 82px;
  z-index: 9999;
  border: none;
  height: 6px;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0px;
  -webkit-appearance: none;
  appearance: none;
  @media (max-width: 991px) {
    top: 70px;
  }
}
progress::-webkit-progress-value {
  background: vars.$title-color;
}
progress::-webkit-progress-bar {
  background: transparent;
}
// =================================== //
// End Progress Bar Styles//
// =================================== //
// =================================== //
// Start Box Shadow Styles//
// =================================== //
.img-box-shadow {
  @include m.filter(drop-shadow(-30px 20px 35px rgba(0, 0, 0, 0.5)));
}
// =================================== //
// End Box Shadow Styles//
// =================================== //
// React Carousel
.carousel .slide {
  background: transparent !important;
}
.carousel .thumb {
  border: 0px !important;
  margin-right: 30px !important;
}
// Video Modal
.modal-video-close-btn {
  cursor: pointer !important;
}

.mobileFlow {
  @media (max-width: 575px) {
    flex-direction: column;
    width: 60% !important;
    margin: 0 auto;
  }
  button:nth-child(1) {
    @media (max-width: 575px) {
      margin-right: 0 !important;
      margin-bottom: 15px !important;
    }
  }
}

input:after {
  content: attr(placeholder) !important;
}

input[value]:not([value=""]) + input:after {
  content: "" !important;
}
