.swiper-slide{
  display: flex;
  justify-content: center
}
.swiper-slide img.mobileScreen{
  width: 100%;
  @media (max-width: 767.98px) {
    height: 85vh !important;
    width: unset
  }
}
.swiper-slide img.slider_Image{
  @media (max-width: 767.98px) {
    height: 45vh !important;
    width: unset
  }
}