
video {
  vertical-align: middle;
  height: 100%;
  width: 100%;
}

section, .section {
  position: relative;
}

section [class^='container'], .section [class^='container'] {
  padding-top: 45px;
  padding-bottom: 45px;
  position: relative;
}

.contact-area{
  min-height: 100vh
}

.video-banner{
  position: relative;
}

.vignette {
  background: linear-gradient(to bottom,black 20%,transparent 100%);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  opacity: 0.75;
}

.video-banner .row {
  color: #fff;
  font-size: 3rem;
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 100%;
    display: flex;
    align-items: center;
}

/* .hero-banner {
  background-color: #f9fbfd;
  background: url("../img/Banner-Bg.png") no-repeat top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: calc(100vh + 70px);
  display: flex;
  align-items: center;

}

.hero-banner:before {
  background: linear-gradient(to bottom,black 30%,transparent 100%);
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.88;
  z-index: auto;
} */

/* .hero-banner .container {
  padding-top: 12rem;
  padding-bottom: 6rem;
}

@media (min-width: 992px) {
  .hero-banner .container {
    padding-bottom: 24rem;
  }
} */

.hero-banner .shape-background {
  z-index: 0;
  opacity: 0.20;
}

.hero-banner .shape-main {
  bottom: 0;
  opacity: 1;
  width: 170%;
  height: 150%;
  background-color: #9F55FF;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(20%, 0) rotate(-30deg);
  transform: translate(20%, 0) rotate(-30deg);
  position: absolute;
}

.hero-banner .shape-main.shadow {
  background-color: rgba(100, 111, 121, 0.15);
  -webkit-transform: translate(19%, 10px) rotate(-30deg);
  transform: translate(19%, 10px) rotate(-30deg);
}

@media (min-width: 768px) {
  .hero-banner .shape-main {
    width: 140%;
  }
}

@media (min-width: 992px) {
  .hero-banner .shape-main {
    bottom: -40px;
    width: 120%;
  }
}

@media (min-width: 1200px) {
  .hero-banner .shape-main {
    width: 110%;
  }
}

.hero-banner .rounded-stripe {
  width: 100%;
  background-color: #8abcfe;
      /* border-radius: 40px; */
  height: 20%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 1;
  -webkit-transform: translate(20%, 0) rotate(-30deg);
  transform: translate(20%, 0) rotate(-30deg);
  bottom: -40px;
  position: absolute;
}

.hero-banner .shape-top {
  top: 0;
  background-image: linear-gradient(-57deg, #5634D0 0%, #4A61D1 100%);
  -webkit-transform: rotate(30deg) translate3D(-45%, -45%, 0);
  transform: rotate(30deg) translate3D(-45%, -45%, 0);
  max-height: 560px;
  -webkit-box-shadow: 0 0 25px 50px rgba(74, 97, 209, 0.5);
  box-shadow: 0 0 25px 50px rgba(74, 97, 209, 0.5);
}

.hero-banner .shape-right {
  background-image: linear-gradient(-57deg, #5634D0 0%, #4A61D1 100%);
  left: 93%;
}

.hero-banner .main-shape-wrapper {
  width: 90%;
}

@media (max-width: 991.98px) {
  .hero-banner .main-shape-wrapper {
    position: relative;
    left: 20%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media (max-width: 767.98px) {
  .hero-banner .main-shape-wrapper {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (min-width: 992px) {
  .hero-banner .main-shape-wrapper {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .hero-banner .main-shape-wrapper {
    -webkit-transform: translateY(-50%) scale(0.75);
    transform: translateY(-50%) scale(0.75);
  }
}

@media (min-width: 1601px) {
  .hero-banner .main-background {
    -webkit-transform: translate3d(0, -3%, 0);
    transform: translate3d(0, -3%, 0);
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(1) {
  -webkit-animation: floating 3.33333s ease-in-out infinite alternate;
  animation: floating 3.33333s ease-in-out infinite alternate;
  bottom: 45%;
  left: 2%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(1) {
    -webkit-animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(2) {
  -webkit-animation: floating 2.23333s ease-in-out infinite alternate;
  animation: floating 2.23333s ease-in-out infinite alternate;
  bottom: 40%;
  left: 12%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(2) {
    -webkit-animation: bubble-left-v 6.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(3) {
  -webkit-animation: floating 2.93333s ease-in-out infinite alternate;
  animation: floating 2.93333s ease-in-out infinite alternate;
  bottom: 56%;
  left: 14%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(3) {
    -webkit-animation: bubble-left-v 8.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 8.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(4) {
  -webkit-animation: floating 2.26667s ease-in-out infinite alternate;
  animation: floating 2.26667s ease-in-out infinite alternate;
  bottom: 51%;
  left: 20%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(4) {
    -webkit-animation: bubble-left-v 6.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(5) {
  -webkit-animation: floating 3.16667s ease-in-out infinite alternate;
  animation: floating 3.16667s ease-in-out infinite alternate;
  bottom: 65%;
  left: 30%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(5) {
    -webkit-animation: bubble-left-v 9.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 9.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(6) {
  -webkit-animation: floating 2.9s ease-in-out infinite alternate;
  animation: floating 2.9s ease-in-out infinite alternate;
  bottom: 44%;
  left: 30%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(6) {
    -webkit-animation: bubble-left-v 8.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 8.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(7) {
  -webkit-animation: floating 2.5s ease-in-out infinite alternate;
  animation: floating 2.5s ease-in-out infinite alternate;
  bottom: 80%;
  left: 40%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(7) {
    -webkit-animation: bubble-left-v 7.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 7.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(8) {
  -webkit-animation: floating 3.33333s ease-in-out infinite alternate;
  animation: floating 3.33333s ease-in-out infinite alternate;
  bottom: 55%;
  left: 38%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(8) {
    -webkit-animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(9) {
  -webkit-animation: floating 2.13333s ease-in-out infinite alternate;
  animation: floating 2.13333s ease-in-out infinite alternate;
  bottom: 50%;
  left: 1%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(9) {
    -webkit-animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(10) {
  -webkit-animation: floating 2.73333s ease-in-out infinite alternate;
  animation: floating 2.73333s ease-in-out infinite alternate;
  bottom: 55%;
  left: 12%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(10) {
    -webkit-animation: bubble-left-v 8.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 8.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(11) {
  -webkit-animation: floating 2.13333s ease-in-out infinite alternate;
  animation: floating 2.13333s ease-in-out infinite alternate;
  bottom: 60%;
  left: 14%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(11) {
    -webkit-animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(12) {
  -webkit-animation: floating 2.6s ease-in-out infinite alternate;
  animation: floating 2.6s ease-in-out infinite alternate;
  bottom: 60%;
  left: 18%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(12) {
    -webkit-animation: bubble-left-v 7.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 7.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(13) {
  -webkit-animation: floating 3.06667s ease-in-out infinite alternate;
  animation: floating 3.06667s ease-in-out infinite alternate;
  bottom: 70%;
  left: 20%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(13) {
    -webkit-animation: bubble-left-v 9.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 9.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(14) {
  -webkit-animation: floating 4.06667s ease-in-out infinite alternate;
  animation: floating 4.06667s ease-in-out infinite alternate;
  bottom: 75%;
  left: 45%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(14) {
    -webkit-animation: bubble-left-v 12.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 12.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(15) {
  -webkit-animation: floating 3.26667s ease-in-out infinite alternate;
  animation: floating 3.26667s ease-in-out infinite alternate;
  bottom: 50%;
  left: 50%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(15) {
    -webkit-animation: bubble-left-v 9.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 9.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

.hero-banner .bubbles-wrapper .bubble:nth-child(16) {
  -webkit-animation: floating 3.53333s ease-in-out infinite alternate;
  animation: floating 3.53333s ease-in-out infinite alternate;
  bottom: 68%;
  left: 34%;
}

@media (min-width: 992px) {
  .hero-banner .bubbles-wrapper .bubble:nth-child(16) {
    -webkit-animation: bubble-left-v 10.6s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 10.6s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%;
  }
}

@media (min-width: 768px) {
  .hero-banner .form {
    width: 90%;
  }
}

.hero-banner .input-group-register .form-control {
  padding: 1.5rem 0 1.5rem 1.5rem;
}

.hero-banner .input-group-register .btn {
  right: 5px;
}

.hero-banner .input-group-register label.error {
  color: #fff;
  background-color: #dc3545;
  border-radius: 15px;
  padding: 0 15px;
}

@media (min-width: 576px) {
  .hero-banner + .features-carousel .container > .cards-wrapper {
    width: 510px;
  }
}

@media (min-width: 768px) {
  .hero-banner + .features-carousel .container > .cards-wrapper {
    width: 690px;
  }
}

@media (min-width: 992px) {
  .hero-banner + .features-carousel .container > .cards-wrapper {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .hero-banner + .features-carousel .container > .cards-wrapper {
    width: 1110px;
  }
}

@media (min-width: 992px) {
  .hero-banner + .features-carousel .container {
    padding-bottom: 16rem;
  }

  .hero-banner + .features-carousel .container > .cards-wrapper {
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.hero-banner + .features-carousel .logo {
  min-height: 48px;
}

croll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.swiper-slide {
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1199px) {
  .navbar, .nav-link{
    padding-left:0;
    padding-right:0
  }
}



@media screen and (max-width: 992px) {
  .banner-content{
    margin-top: 100px;
  }
}
@media screen and (min-width: 640.98px) {
  .scan-data{
    top: 85px;
    left: 25px;
    z-index: 9
  }
  .scan-data img{
    width: 200px;
  }
}
@media screen and (max-width: 640px) {
  .scan-data{
    top: 85px;
    right: 25px;
    z-index: 9

  }
  .scan-data img{
    width: 120px;
  }
}
@media screen and (min-width: 768px) {
  .lg-display{
    display: flex;
  }
  .sm-display{
    display: none;
  }
}
@media screen and (max-width: 767.98px) {
  .lg-display{
    display: none;
  }
  .sm-display{
    display: flex;
  }
}
