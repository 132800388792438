//    Mixins
@mixin transition($args: all 0.3s ease 0s) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin background-clip($args: text) {
    -webkit-background-clip: $args;
}

@mixin transition-duration($args1, $args2) {
    -webkit-transition-duration: $args1, $args2;
    -moz-transition-duration: $args1, $args2;
    transition-duration: $args1, $args2;
}

@mixin transition-delay($args1, $args2) {
    -webkit-transition-delay: $args1, $args2;
    -moz-transition-delay: $args1, $args2;
    -o-transition-delay: $args1, $args2;
    transition-delay: $args1, $args2;
}

@mixin transition-property($args1, $args2) {
    -webkit-transition-property: $args1, $args2;
    -moz-transition-property: $args1, $args2;
    -o-transition-property: $args1, $args2;
    transition-property: $args1, $args2;
}

@mixin gradient($deg, $args1, $args2) {
    background: -webkit-linear-gradient($deg, $args1, $args2);
    background: -moz-linear-gradient($deg, $args1, $args2);
    background: -ms-linear-gradient($deg, $args1, $args2);
    background: -o-linear-gradient($deg, $args1, $args2);
    background: linear-gradient($deg, $args1, $args2);
}

@mixin gradient2($deg, $args1, $args2, $args3) {
    background: -webkit-linear-gradient($deg, $args1, $args2, $args3);
    background: -moz-linear-gradient($deg, $args1, $args2, $args3);
    background: -ms-linear-gradient($deg, $args1, $args2, $args3);
    background: -o-linear-gradient($deg, $args1, $args2, $args3);
    background: linear-gradient($deg, $args1, $args2, $args3);
}

@mixin border-gradient($deg, $args1, $args2) {
    border-image: -moz-linear-gradient($deg, $args1, $args2);
    border-image: -ms-linear-gradient($deg, $args1, $args2);
    border-image: -o-linear-gradient($deg, $args1, $args2);
    border-image: -webkit-linear-gradient($deg, $args1, $args2);
    border-image: linear-gradient($deg, $args1, $args2);
}

@mixin filter($filter) {
    filter: $filter;
    -webkit-filter: $filter;
    -moz-filter: $filter;
    -ms-filter: $filter;
}

@mixin skew($rotate-val) {
    transform: skewY($rotate-val);
    -webkit-transform: skewY($rotate-val);
    -moz-transform: skewY($rotate-val);
    -o-transform: skewY($rotate-val);
}

@mixin gradient-image-bg($img-name, $gr-cl1, $gr-cl2) {
    background: url("../img/#{$img-name}.png") no-repeat center,
    -moz-linear-gradient(90deg,
    $gr-cl1 0,
    $gr-cl2 100%);
    background: url("../img/#{$img-name}.png") no-repeat center,
    -webkit-gradient(90deg,
    $gr-cl1 0,
    $gr-cl2 100%);
    background: url("../img/#{$img-name}.png") no-repeat center,
    -webkit-linear-gradient(90deg,
    $gr-cl1 0,
    $gr-cl2 100%);
    background: url("../img/#{$img-name}.png") no-repeat center,
    -o-linear-gradient(90deg,
    $gr-cl1 0,
    $gr-cl2 100%);
    background: url("../img/#{$img-name}.png") no-repeat center,
    -ms-linear-gradient(90deg,
    $gr-cl1 0,
    $gr-cl2 100%);
    background: url("../img/#{$img-name}.png") no-repeat center,
    linear-gradient(90deg,
    $gr-cl1 0,
    $gr-cl2 100%);
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}

@mixin transform-origin($value) {
    -webkit-transform-origin: $value;
    -moz-transform-origin: $value;
    -ms-transform-origin: $value;
    -o-transform-origin: $value;
    transform-origin: $value;
}

@mixin backface-visibility($value) {
    -webkit-backface-visibility: $value;
    -moz-backface-visibility: $value;
    backface-visibility: $value;
}

@mixin calc($property,
$expression) {
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: -moz-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin placeholder {
    &.placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($args) {
    -webkit-animation: $args;
    -moz-animation: $args;
    -o-animation: $args;
    animation: $args;
}

// Medium Layout: 1280px
@mixin medium {
    @media (min-width: 992px) and (max-width: 1400px) {
        @content;
    }
}

// Tablet Layout: 768px
@mixin tablet {
    @media (min-width: 768px) and (max-width: 1200px) {
        @content;
    }
}

// Mobile Layout: 320px
@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

// Wide Mobile Layout: 480px
@mixin wide-mobile {
    @media (min-width: 480px) and (max-width: 767px) {
        @content;
    }
}

@mixin cmq($min,
$max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}