/* =================================== */

/*  Footer Styles
/* =================================== */

@use "./variables" as vars;
@use "./mixins" as m;

.footer-area {
  background: vars.$title-color;
  padding: 15px 0px;
  @media (max-width: 991px) {
    padding: 50px 0px;
  }
}

.gradient-bg-2 {
  @include m.gradient(90deg, vars.$primary-color3, vars.$primary-color4);
}

.single-footer-widget {
  h4 {
    color: vars.$white;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .footer-lists {
    padding-bottom: 30px;
    @media (max-width: 991px) {
      padding-bottom: 20px;
    }
    li {
      padding-bottom: 10px;
      a {
        color: vars.$text-color;
        &:hover {
          color: vars.$white;
        }
      }
    }
  }
}

.footer-logo {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    left: 160px;
    top: 16px;
    height: 1px;
    width: 100%;
    background: #333333;
  }
}
.copy-right-text {
  @media (max-width: 767px) {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
}

.copyright-area {
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
  }
  padding-top: 0px;
  .copy-right-text {
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
    a {
      color: vars.$white;
      &:hover {
        color: vars.$primary-color2;
      }
    }
  }
  .playstore-btns {
    text-align: right;
    @media (max-width: 767px) {
      text-align: left;
    }
    .primary-btn {
      @media (max-width: 472px) {
        margin-bottom: 20px;
      }
    }
  }
  .primary-btn {
    background: transparent;
    border-color: #333333 !important;
    h4 {
      color: vars.$white !important;
    }
    span {
      color: #444;
    }
    p {
      color: #6d6d6d;
    }
    &:hover {
      @extend .gradient-bg-2;
      span {
        color: vars.$white;
      }
      p {
        color: vars.$white;
      }
    }
  }
}

/*-------- end footer Area -------------*/
