// =================================== //
//  Start Main Version Banner Styles//
// =================================== //

@use "./variables" as vars;

.video-banner {
  min-height: 50vh;
  .row {
    @media (max-width: 767.98px) {
      align-items: flex-end;
    }
    @media (max-width: 575.98px) {
      align-items: flex-start;
    }
  }
  .gap-y {
    @media (max-width: 575.98px) {
      align-items: flex-start;
    }
  }
  .secondary-btn {
    background: #03a9f4 !important;
    color: #fff;
    font-weight: bold;
  }
  .container {
    padding-bottom: 15px;
  }
  .mobileFlow {
    margin: 0;
  }
  .container-fluid {
    padding-left: 20%;
    @media (max-width: 1680px) {
      max-width: 1140px;
      padding-left: 15px;
    }
    @media (max-width: 1199px) {
      max-width: 960px;
    }
    @media (max-width: 991px) {
      max-width: 720px;
    }
  }
  .banner-content {
    @media (max-width: 1440px) {
      // margin-top: 150px;
    }
    @media (max-width: 991px) {
      // margin-top: 180px;
    }
    @media (max-width: 575px) {
      padding-top: 0 !important;
    }
    h1 {
      color: vars.$white;
      margin-bottom: 30px !important;
      font-weight: 700;
      font-size: 52px;
      @media (max-width: 1199px) {
        font-size: 42px;
      }
      @media (max-width: 991px) {
        font-size: 34px;
        margin-top: 0px;
      }
      @media (max-width: 767px) {
        font-size: 48px;
        margin-top: 50px;
      }
      @media (max-width: 575px) {
        font-size: 44px;
        margin-top: 0px;
        margin-bottom: 15px !important;
      }
    }
    h3 {
      color: vars.$white;
      margin-bottom: 30px !important;
      font-weight: 700;
      @media (max-width: 1199px) {
        font-size: 24px;
      }
      @media (max-width: 991px) {
        font-size: 20px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
      }
      @media (max-width: 575px) {
        font-size: 18px;
      }
    }
    p {
      color: vars.$white;
      max-width: 410px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .video-popup {
      margin-top: 20px;
    }
  }
  .banner-device {
    margin-top: 120px;
    padding-left: 70px;
    @media (max-width: 1440px) {
      text-align: center;
      margin-top: 150px;
    }
    .banner-img1 {
      top: 100px;
      @media (max-width: 1440px) {
        top: 50px;
      }
    }
    .banner-img2 {
      top: 100px;
      left: -100px;
      @media (max-width: 1440px) {
        top: 70px;
        left: -70px;
      }
    }
    .banner-img3 {
    }
    .banner-img4 {
      top: -100px;
      @media (max-width: 1440px) {
        top: -50px;
      }
    }
    .banner-img {
      width: 40%;
      position: relative;
      @media (max-width: 1680px) {
        width: 49%;
      }
      @media (max-width: 1440px) {
        width: 40%;
      }
      @media (max-width: 991px) {
        display: none;
      }
    }
    img {
      width: 55%;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

.content {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;

  .content-overlay {
    background: rgb(0, 175, 217);
    background: linear-gradient(
      90deg,
      rgba(0, 175, 217, 0.7) 0%,
      rgba(93, 87, 255, 0.7) 100%
    );
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

  //   &:hover .content-overlay{
  // 	opacity: 1;
  //   }
  .card-img-top {
    width: 100%;
  }
}

.card:hover .content-overlay {
  opacity: 1;
}
