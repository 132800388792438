// @import "./theme/variables";
// @import "./theme/mixins";
// @import "./theme/flexbox";
// @import "./theme/predefine";

// @import "./theme/header";
// @import "./theme/banner";
// @import "./theme/flipitem";
// @import "./theme/about";
// @import "./theme/features";
// @import "./theme/pricecompare";
// @import "./theme/reset";
// @import "./theme/screenshots";
// @import "./theme/contact";
// @import "./theme/footer";

@use "./theme/variables";
@use "./theme/mixins";
@use "./theme/flexbox";
@use "./theme/predefine";

@use "./theme/header";
@use "./theme/banner";
@use "./theme/flipitem";
@use "./theme/about";
@use "./theme/features";
@use "./theme/pricecompare";
@use "./theme/reset";
@use "./theme/screenshots";
@use "./theme/contact";
@use "./theme/footer";

.card-title {
  min-height: 80px;
}

.card-img,
.card-img-top:hover img {
  -webkit-transform: scale(1.2) rotate(-7deg);
  -moz-transform: scale(1.2) rotate(-7deg);
  -ms-transform: scale(1.2) rotate(-7deg);
  -o-transform: scale(1.2) rotate(-7deg);
  transform: scale(1.2) rotate(-7deg);
}

.card:hover {
  /* background: red; */
  border: 1px solid #0297fe;
  cursor: pointer;
  &.card-img img:before {
    background: #abcdef;
    z-index: 999;
  }
}
.card-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
}

.section-blue {
  background: #0297fe !important;
  color: #fff !important;
  .section-title {
    h2 {
      color: #fff !important;
    }
    .line {
      background: #fff !important;
    }
  }
}
section {
  font-size: 16px;
  font-weight: 500;
}

.connect-icon {
  border-radius: 50%;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
