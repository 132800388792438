
.color-white {
	color: #fff;
  }
  
  .box-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
  }
  
  @media screen and (min-width:1380px) {
	.box-container {
	  flex-direction: row
	}
  }
  
  .box-item {
	position: relative;
	-webkit-backface-visibility: hidden;
	width: 100%;
	margin-bottom: 35px;
	max-width: 100%;
  }
  
  .flip-box {
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	perspective: 1000px;
	-webkit-perspective: 1000px;
  }
  
  .flip-box-front,
  .flip-box-back {
	background-size: cover;
	background-position: center;
	border-radius: 8px;
	min-height: 200px;
	-ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
	transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
	-webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
  }
  
  .flip-box-front {
	-ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-front {
	-ms-transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
  }
  
  .flip-box-back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	
	-ms-transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-back {
	-ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
  }
  
  .flip-box .inner {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 25px;
	outline: 1px solid transparent;
	-webkit-perspective: inherit;
	perspective: inherit;
	z-index: 2;
	
	transform: translateY(-50%) translateZ(60px) scale(.94);
	-webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
	-ms-transform: translateY(-50%) translateZ(60px) scale(.94);
	top: 50%;
  }
  
  .flip-box-header {
	font-size: 24px;
  }
  
  .flip-box p {
	font-size: 18px;
	line-height: 1.3em;
  }
  
  .flip-box-img {
	margin-top: 25px;
  }
  
  .flip-box-button {
	background-color: transparent;
	border: 2px solid #fff;
	border-radius: 2px;
	color: #fff;
	cursor: pointer;
	font-size: 20px;
	font-weight: bold;
	margin-top: 25px;
	padding: 15px 20px;
	text-transform: uppercase;
  }