// =================================== //
//  Start Contact Styles//
// =================================== //

@use "./variables" as vars;
@use "./mixins" as m;

#map {
  width: 100%;
  height: 650px;
}
.contact-area {
  margin-bottom: 50px;
}
.contact-info {
  @media (max-width: 991px) {
    margin-top: 50px;
  }
  .single-info {
    position: relative;
    margin-bottom: 25px;
    .info-icon {
      font-size: 25px;
      font-weight: 500;
      svg {
        color: vars.$primary-color2;
      }
    }
    .info-desc {
      padding-left: 20px;
      p {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      h5 {
        margin-bottom: 5px;
      }
    }
  }
}

.contact-form-area {
  margin-top: 0px;
  @media (max-width: 767.98px) {
    margin-top: 40px;
  }
  input.common-input {
    width: 100%;
    line-height: 38;
    border-radius: 50px;
    padding: 0 20px;
    font-size: 12px;
  }
  input,
  textarea {
    &:focus {
      border-color: #dddddd;
      box-shadow: none;
    }
    @include m.placeholder {
      font-size: 12px;
      color: vars.$text-color;
    }
  }
  textarea.common-textarea {
    border-radius: 20px;
    height: 110px;
    resize: none;
    padding: 10px 20px;
  }
}
#sendMessageBtn {
  color: #adad85;
}

// =================================== //
//  End Contact Styles//
// =================================== //
