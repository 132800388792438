.pricecompare{
article {
    width:100%;
    max-width:1000px;
    margin:0 auto;
    position:relative;
  }
  ul {
    display:flex;
    top:0px;
    z-index:10;
    padding-bottom:14px;
  }
  li {
    list-style:none;
    flex:1;
  }
  li:last-child {
    border-right:1px solid #DDD;
  }
  button {
    width:100%;
    border: 1px solid #DDD;
    border-right:0;
    border-top:0;
    padding: 10px;
    background:#FFF;
    font-size:14px;
    font-weight:bold;
    height:60px;
    color:#999
  }
  li.active button {
    background:#0297fe;
    color:#fff;
  }
  table { border-collapse:collapse; table-layout:fixed; width:100%; }
  th { background:#F5F5F5; display:none; }
  td, th {
    height:53px
  }
  td,th { border:1px solid #DDD; padding:10px; empty-cells:show; }
  td,th {
    text-align:left;
  }
  td+td, th+th {
    text-align:center;
    display:none;
  }
  td.default {
    display:table-cell;
  }
  .bg-blue {
    border-top:3px solid #0297fe;
  }
  .sep {
    background:#F5F5F5;
    font-weight:bold;
  }
  .txt-l { font-size:28px; font-weight:bold; }
  .txt-top { position:relative; top:-9px; left:2px; }
  .txt-top-l { position:relative; top:-9px; left:-2px; }
  .tick { font-size:18px; color:#2CA01C; }
  .hide {
    border:0;
    background:none;
  }
  
  @media (min-width: 640px) {
    ul {
      display:none;
    }
    td,th {
      display:table-cell !important;
    }
    td,th {
      width: 330px;
    
    }
    td+td, th+th {
      width: auto;
    }
  }
  
}
span.info{
  background: gray;
  width: 15px;
  height: 15px;
  font-size: 10px;
  color: #fff;
  display: block;
    text-align: center;
    border-radius: 15px;
    font-weight: bold;
    margin-left: 4px
}