$primary-font	: 'PTSansRegular', Arial, Helvetica, sans-serif;
$title-font		: 'PTSansRegular', Arial, Helvetica, sans-serif;

$primary-color	: #5D57FF;
$primary-color2	: #0297fe;
$primary-color3	: #5D57FF; //#f13d80
$primary-color4 : #0297fe; //#f48464
$secondary-color : #FFFFFF; //#f48464
$secondary-color2 : #FFFFFF; //#f48464
$title-color	: #222222;
$text-color		: #888888;

$white			: #ffffff;
$offwhite		: #f9f9f9;
$black			: #000000;

.app-primary{
    background:#11c7af
}
.app-secondary{
    background: #f57962
}
.app-success{
    background: #3a9ac6
}
.app-danger{
    background: #c34141
}
.app-info{
    background: #a66bbb
}
.app-brown{
    background: #bc8a61
}
.app-blue{
    background: #3c3b6e
}
.app-red{
    background: #0297fe
}




